"use client";

import {
  type AppRouterErrorProps,
  appRouterSsrErrorHandler,
} from "@highlight-run/next/ssr";

import { Button } from "@/components/shadcn/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/shadcn/card";

export default appRouterSsrErrorHandler(
  ({ error, reset }: AppRouterErrorProps) => {
    console.error(error);

    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-background">
        <Card className="w-1/2">
          <CardHeader>
            <CardTitle className="text-3xl font-bold text-destructive">
              Oops! Something went wrong
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-muted-foreground">
              We apologize for the inconvenience. Let&apos;s try to get things
              back on track.
            </p>
          </CardContent>
          <CardFooter>
            <Button onClick={() => reset()} variant="outline">
              Try again
            </Button>
          </CardFooter>
        </Card>
      </div>
    );
  },
);
